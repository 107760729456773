import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/use-actions'
import Button from '../../ui/Button/Button'

import { AiOutlineAppstoreAdd, AiOutlineNumber } from 'react-icons/ai'
import { BiCategoryAlt, BiSolidWallet } from 'react-icons/bi'
import { LuClipboardList } from 'react-icons/lu'
import { Link } from 'react-router-dom'
import styles from './Modal.module.scss'
import Loading from './Loading';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import oculusLogo from './oculus.gif';

const decodeQueryParams = (queryString) => {
    const params = new URLSearchParams(queryString)
    // Get the user parameter and decode it
    const userParam = params.get('user');
    const decodedUser = decodeURIComponent(userParam);

    // Parse the decoded user parameter as JSON
    const userObject = JSON.parse(decodedUser);
    // console.log('userObject', userObject)
    // Extract the desired fields
    const id = userObject.id;
    const firstName = userObject.first_name;
    const username = userObject.username;

    return { id, firstName, username };
}

export default function Modal() {
    const { getAllQueries, setUser } = useActions()
    const user = useSelector(state => state.modalSlice.user)
    const noob = useSelector(state => state.modalSlice.noob)
    const tier = useSelector(state => state.modalSlice.tier)
    const logs = useSelector(state => state.modalSlice.logs)
    const cmb_balance = useSelector(state => state.modalSlice.cmb_balance)
    const cmb_tier = useSelector(state => state.modalSlice.cmb_tier)
    const autobuys = useSelector(state => state.modalSlice.autobuys)
    const loading = useSelector(state => state.modalSlice.loading)
    const referral_code = useSelector(state => state.modalSlice.referral_code)
  const [isCopied, setIsCopied] = useState(false);
  const { isLoading } = useSelector((state) => state.modalSlice); // Get the state from Redux

    const handleCopyToClipboard = () => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = `https://t.me/OculusSolana_Bot?start=${referral_code}`;

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);
  };

  const fetchDataAsync = async () => {

    try {





      const { id, firstName, username } = decodeQueryParams(
        window.Telegram.WebApp.initData
      );
    



/*

      const id = 1000498160
      const firstName = 'Hoosto'
      const username = 'hoosto'

*/

      
      // console.log('user', user)
      // console.log('user.telegram_id', user.telegram_id)

      let x = user.telegram_id === undefined ? true : false

      // console.log(window.Telegram.WebApp.initData)
 

      if (x) {

        if (username !== undefined) {



        toast.success(`Welcome back ${username}`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })




        } else {



        toast.success(`Welcome back ${id}`, {
              icon: false,
    className: styles.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })


        }


      }
      
      setUser({
        telegram_id: id,
    
        firstName,
        hash: window.Telegram.WebApp.initData,
        username,
        ref_code: null
   
      });

      let data = {
        telegram_id: id,
 
        hash: window.Telegram.WebApp.initData,
        firstName,
        username,
        ref_code: null

   
      };


      await getAllQueries(data);

    } catch (error) {
      // Handle the error here
      console.error(error);
    }
  }

    useEffect(() => {
    fetchDataAsync();
  }, []);

  const [value, setValue] = useState('on');

  const handleToggle = (event, newValue) => {
    if (newValue !== null) {
      setValue(newValue);
    }
  };

    return (
    <div className={styles.mainContainer}>
      <div className={styles.mainCard}>
<img src={oculusLogo} style={{ maxWidth: '300px', margin: '25px 0' }}  />
        {/*<div className={styles.mainTitle}>OCULUS X {user.username !== undefined && user.username !== null ? user.username : ''}</div>*/}
        <div className={styles.mainTitle}>OCULUS X SOLANA</div>

      {isLoading && (
        <div className={styles.loadingOverlay}>
          <div className={styles.spinner}></div>

        </div>
      )}
      
              <div className={styles.mainUserTier}>{tier !== undefined && tier !== null && tier === 'godseye' ? `God's Eye` : ''} ... <span className={styles.ocuBalance}>{parseFloat(cmb_balance).toFixed(0)} $OCU</span></div>

        <div className={styles.mainUser}>{/*user.username !== undefined && user.username !== null ? user.username : ''*/}</div>
       
  {
  tier === 'xyz' ? (
    <>
     

    </>
  ) : tier === 'godseye' ? (
    <>
      {godseye.map((link) => (
        <ModalLink
          key={link.href}
          href={link.href}
          icon={link.icon}
          text={link.text}
        />
      ))}
    </>
  ) : tier === 'based' ? (
    <>
     
    </>
  ) : tier === 'mastercraft' ? (
    <>
    </>
  ) : ''


}





      </div>
    </div>
  );

}

const ModalLink = ({ href, text, icon }) => {
    return (
        <Link to={href}>
        <button className={styles.button}>{text}</button>
    </Link>
    )
}

{/*
const links = [{
        href: '/mastercraft/create-filter',
        text: 'Create profile',
        icon: <AiOutlineAppstoreAdd />
    },
    {
        href: '/mastercraft/show-filters',
        text: 'View profiles',
        icon: <LuClipboardList />
    },

]
*/}


const godseye = [

    {
        href: '/godseye/show-filters',
        text: 'Profile Manager',
        icon: <LuClipboardList />
    },
{
        href: '/godseye/create-filter',
        text: 'Create profile',
        icon: <AiOutlineAppstoreAdd />
    },

     {
        href: '/godseye/show-bags',
        text: 'Simulated Buys',
        icon: <LuClipboardList />
    },
     {
        href: '/godseye/show-bags-real',
        text: 'Automated Buys',
        icon: <LuClipboardList />
    },



   
{
            href: '/godseye/generate-wallet',
        text: 'Wallet Manager',
                icon: <LuClipboardList />
              },

                  {
        href: '/godseye/generate-referral-code',
        text: 'Referral List',
        icon: <AiOutlineAppstoreAdd />
    },
]

const mastercraft = [

    {
        href: '/mastercraft/show-filters',
        text: 'Profile Manager',
        icon: <LuClipboardList />
    },
{
        href: '/mastercraft/create-filter',
        text: 'Create profile',
        icon: <AiOutlineAppstoreAdd />
    },

     {
        href: '/mastercraft/show-bags',
        text: 'Simulated Buys',
        icon: <LuClipboardList />
    },

     {
        href: '/mastercraft/show-bags-real',
        text: 'Automated Buys',
        icon: <LuClipboardList />
    },


   
{
            href: '/mastercraft/generate-wallet',
        text: 'Wallet Manager',
                icon: <LuClipboardList />
              },

                  {
        href: '/mastercraft/generate-referral-code',
        text: 'Referral List',
        icon: <AiOutlineAppstoreAdd />
    },
]

const based = [

    {
        href: '/mastercraft/show-filters',
        text: 'Profile Manager',
        icon: <LuClipboardList />
    },
{
        href: '/mastercraft/create-filter',
        text: 'Create profile',
        icon: <AiOutlineAppstoreAdd />
    },

     {
        href: '/mastercraft/show-bags',
        text: 'Simulated Buys',
        icon: <LuClipboardList />
    },

     {
        href: '/mastercraft/show-bags-real',
        text: 'Automated Buys',
        icon: <LuClipboardList />
    },


   
{
            href: '/mastercraft/generate-wallet',
        text: 'Wallet Manager',
                icon: <LuClipboardList />
              },

                  {
        href: '/mastercraft/generate-referral-code',
        text: 'Referral List',
        icon: <AiOutlineAppstoreAdd />
    },
]



