import React, { useEffect } from 'react'
import { AiFillEdit } from 'react-icons/ai'
import { BsFillPlayCircleFill, BsFillPauseCircleFill } from 'react-icons/bs'
import { BiSolidTrash } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import AutoBuy from './AutoBuy'
import s from './ShowFilters.module.scss'

export default function ShowFiltersGM() {
  const nav = useNavigate()
  const { removeSavedQuery, editQuery } = useActions()
  const { deleteQuery, deleteAutoBuy, getAllQueries, setUser, editAutoBuyQuery, toggleFilter, toggleAutoBuy, resetAutobuyQuery } = useActions()
  const { savedQueries, user, autobuy, wallets, tier } = useSelector((state) => state.modalSlice)

  const removeQuery = (id) => {

    let data = {
        id,
        telegram_id: user.telegram_id,
        hash: user.hash
    }

    deleteQuery(data)
    // toast.success('Filter removed')
      toast.success('Profile Removed', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
  }

  const toggleQuery = (item, bool) => {

    let data = {
        id: item.id,
        telegram_id: user.telegram_id,
        toggle: !item.paused
    }

    toggleFilter(data)

    toast.success(`${item.paused ? `Scanning mainnet with ${item.name}` : `Paused ${item.name}`}`, {

      position: toast.POSITION.BOTTOM_RIGHT

    })

  }

  const removeAutobuyQuery = (item) => {

    let data = {
      
      id: item.id,
      telegram_id: user.telegram_id,

    }

    deleteAutoBuy(data)

    //

  }

  const toggleAutoBuyQuery = async (item, index) => {

    let data = {
        id: item.autobuy[index].id,
        telegram_id: user.telegram_id,
        toggle: !item.autobuy[index].paused
    }

    await toggleAutoBuy(data)

    toast.success(`${item.autobuy[index].paused ? `Autobuy initiated with ${item.autobuy[index].name}` : `Autobuy paused on ${item.autobuy[index].name}`}`, {

      position: toast.POSITION.BOTTOM_RIGHT

    })

  }

const filteredSavedQueries = savedQueries.filter(item => item.deleted === false);
  
  return (
    <div className={s.container}>
      <div className={s.title}>Current Profiles X</div>
      {!filteredSavedQueries.length ? (
        <span className={s.noQueries}>No Profiles</span>
      ) : (
        <ul className={s.items}>
          {filteredSavedQueries.map((item, index) => (
            <li key={item.id}>
              <div style={{display: 'block', width: '100%'}}>

        
                      <>
                        <span style={{float: 'left'}}>{item.name}</span> 
                        <div style={{float: 'right'}}>
                          <button
                            onClick={() => {
                              editQuery(item)
                              nav('/free/create-filter')
                            }}
                          >
                            <AiFillEdit />
                          </button>
                          <button onClick={() => removeQuery(item.id)}>
                            <BiSolidTrash /> 
                          </button>

                          <button onClick={() => toggleQuery(item, !item.paused)}>
                            {item.paused ? <BsFillPlayCircleFill /> : <BsFillPauseCircleFill />}
                          </button>

                          {
                            item.autobuy === undefined
                              ?  <button onClick={() => {

                                    if (wallets.length === 0) {

                                      return toast.error('Create a wallet first', {
                                        position: toast.POSITION.BOTTOM_RIGHT
                                      })

                                    } else {


                                      editAutoBuyQuery({
                                 name: '',
        wallets: [],
        gas: [],
        eth_amount: '',
        max_slippage: '',
        max_gas: '',
                                        filterId: item.id,
                                        filterName: item.name
                                      })


                                      nav(`/free/show-filters/${item.id}/auto-buy`)

                                    }


                                  }}>
                                    Add Auto Buy
                                  </button>
                              : ''
                          }
                        </div>
                      </>
                 

                   {
                    item.autobuy && item.autobuy.length > 0 && (
                      <div style={{ display: 'block', clear: 'left', paddingTop: '15px' }}>
                        {item.autobuy.map((autoBuyItem, index) => (
                          <div key={index} style={{ width: '100%', display: 'block' }}>
                                
                                {
                                  autoBuyItem.deleted === false && (
                                       <>
                                  <span style={{ float: 'left', display: 'block' }}>└ AB: {autoBuyItem.name}</span>
                                  <div style={{ float: 'right' }}>
                                    <button
                                      onClick={() => {
                                        
                                        editAutoBuyQuery(autoBuyItem)

                                        nav(`/free/show-filters/${item.id}/auto-buy`);

                                      }}
                                    >
                                      <AiFillEdit />
                                    </button>

                                    <button onClick={() => removeAutobuyQuery(autoBuyItem)}>
                                      <BiSolidTrash />
                                    </button>

                                    <button onClick={() => toggleAutoBuyQuery(item, index)}>
                                      {autoBuyItem.paused === false ? <BsFillPauseCircleFill /> : <BsFillPlayCircleFill />}
                                    </button>
                                  </div>
                                </>
                                  )
                                }
                             
                             
                          </div>
                        ))}
                      </div>
                    )
                  }

              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
