import React from 'react'

import { createBrowserRouter } from 'react-router-dom'
import Dashboard from './components/screens/Dashboard/Dashboard'
import Sync from './components/screens/Sync/Sync'
import FilterCreator from './components/screens/Modal/FilterCreator2/FilterCreator'
import FilterCreatorMasterCraft from './components/screens/Modal/FilterCreatorMasterCraft/FilterCreator'
import FilterCreatorGM from './components/screens/Modal/FilterCreator/FilterCreatorGM'
import EditFilterGM from './components/screens/Modal/EditFilter2/EditFilterGM'
import EditFilterMasterCraft from './components/screens/Modal/EditFilterMasterCraft/EditFilter'
import GenerateWallet from './components/screens/Modal/GenerateWallet2/GenerateWallet'
import GenerateCode from './components/screens/Modal/GenerateCode/GenerateCode'
import Modal from './components/screens/Modal/Modal'
import ModalX from './components/screens/Modal/ModalX'
import ModalLayout from './components/screens/Modal/ModalLayout'
import ShowBugs from './components/screens/Modal/ShowBugs/ShowBugs'
import ShowFilters from './components/screens/Modal/ShowFilters2/ShowFilters'
import ShowFiltersGM from './components/screens/Modal/ShowFilters2/ShowFiltersGM'
import ShowFiltersMasterCraft from './components/screens/Modal/ShowFiltersMasterCraft/ShowFilters'
import Logs from './components/screens/Modal/Logs/Logs'
import Tokens from './components/screens/Modal/Tokens/Tokens'
import AutoBuyCreator from './components/screens/Modal/AutoBuyCreator2/AutoBuyCreator'
import ShowBags from './components/screens/Modal/ShowBags2/ShowBags'
import ShowBagsReal from './components/screens/Modal/ShowBagsReal2/ShowBags'
import EditAutoBuy from './components/screens/Modal/EditAutoBuy2/AutoBuyCreator'



import FreeFilterCreator from './components/screens/Free/FilterCreator2/FilterCreator'
import FreeFilterCreatorMasterCraft from './components/screens/Free/FilterCreatorMasterCraft/FilterCreator'
import FreeFilterCreatorGM from './components/screens/Free/FilterCreator/FilterCreatorGM'
import FreeEditFilterGM from './components/screens/Free/EditFilter2/EditFilterGM'
import FreeEditFilterMasterCraft from './components/screens/Free/EditFilterMasterCraft/EditFilter'
import FreeGenerateWallet from './components/screens/Free/GenerateWallet2/GenerateWallet'
import FreeGenerateCode from './components/screens/Free/GenerateCode/GenerateCode'
import FreeModal from './components/screens/Free/Modal'
import FreeModalX from './components/screens/Free/ModalX'
import FreeModalLayout from './components/screens/Free/ModalLayout'
import FreeShowBugs from './components/screens/Free/ShowBugs/ShowBugs'
import FreeShowFilters from './components/screens/Free/ShowFilters2/ShowFilters'
import FreeShowFiltersGM from './components/screens/Free/ShowFilters2/ShowFiltersGM'
import FreeShowFiltersMasterCraft from './components/screens/Free/ShowFiltersMasterCraft/ShowFilters'
import FreeLogs from './components/screens/Free/Logs/Logs'
import FreeTokens from './components/screens/Free/Tokens/Tokens'
import FreeAutoBuyCreator from './components/screens/Free/AutoBuyCreator2/AutoBuyCreator'
import FreeShowBags from './components/screens/Free/ShowBags2/ShowBags'
import FreeShowBagsReal from './components/screens/Free/ShowBagsReal2/ShowBags'
import FreeEditAutoBuy from './components/screens/Free/EditAutoBuy2/AutoBuyCreator'



import BasicFilterCreator from './components/screens/Basic/FilterCreator2/FilterCreator'
import BasicFilterCreatorMasterCraft from './components/screens/Basic/FilterCreatorMasterCraft/FilterCreator'
import BasicFilterCreatorGM from './components/screens/Basic/FilterCreator/FilterCreatorGM'
import BasicEditFilterGM from './components/screens/Basic/EditFilter2/EditFilterGM'
import BasicEditFilterMasterCraft from './components/screens/Basic/EditFilterMasterCraft/EditFilter'
import BasicGenerateWallet from './components/screens/Basic/GenerateWallet2/GenerateWallet'
import BasicGenerateCode from './components/screens/Basic/GenerateCode/GenerateCode'
import BasicModal from './components/screens/Basic/Modal'
import BasicModalX from './components/screens/Basic/ModalX'
import BasicModalLayout from './components/screens/Basic/ModalLayout'
import BasicShowBugs from './components/screens/Basic/ShowBugs/ShowBugs'
import BasicShowFilters from './components/screens/Basic/ShowFilters2/ShowFilters'
import BasicShowFiltersGM from './components/screens/Basic/ShowFilters2/ShowFiltersGM'
import BasicShowFiltersMasterCraft from './components/screens/Basic/ShowFiltersMasterCraft/ShowFilters'
import BasicLogs from './components/screens/Basic/Logs/Logs'
import BasicTokens from './components/screens/Basic/Tokens/Tokens'
import BasicAutoBuyCreator from './components/screens/Basic/AutoBuyCreator2/AutoBuyCreator'
import BasicShowBags from './components/screens/Basic/ShowBags2/ShowBags'
import BasicShowBagsReal from './components/screens/Basic/ShowBagsReal2/ShowBags'
import BasicEditAutoBuy from './components/screens/Basic/EditAutoBuy2/AutoBuyCreator'

import ProFilterCreator from './components/screens/Pro/FilterCreator2/FilterCreator'
import ProFilterCreatorMasterCraft from './components/screens/Pro/FilterCreatorMasterCraft/FilterCreator'
import ProFilterCreatorGM from './components/screens/Pro/FilterCreator/FilterCreatorGM'
import ProEditFilterGM from './components/screens/Pro/EditFilter2/EditFilterGM'
import ProEditFilterMasterCraft from './components/screens/Pro/EditFilterMasterCraft/EditFilter'
import ProGenerateWallet from './components/screens/Pro/GenerateWallet2/GenerateWallet'
import ProGenerateCode from './components/screens/Pro/GenerateCode/GenerateCode'
import ProModal from './components/screens/Pro/Modal'
import ProModalX from './components/screens/Pro/ModalX'
import ProModalLayout from './components/screens/Pro/ModalLayout'
import ProShowBugs from './components/screens/Pro/ShowBugs/ShowBugs'
import ProShowFilters from './components/screens/Pro/ShowFilters2/ShowFilters'
import ProShowFiltersGM from './components/screens/Pro/ShowFilters2/ShowFiltersGM'
import ProShowFiltersMasterCraft from './components/screens/Pro/ShowFiltersMasterCraft/ShowFilters'
import ProLogs from './components/screens/Pro/Logs/Logs'
import ProTokens from './components/screens/Pro/Tokens/Tokens'
import ProAutoBuyCreator from './components/screens/Pro/AutoBuyCreator2/AutoBuyCreator'
import ProShowBags from './components/screens/Pro/ShowBags2/ShowBags'
import ProShowBagsReal from './components/screens/Pro/ShowBagsReal2/ShowBags'
import ProEditAutoBuy from './components/screens/Pro/EditAutoBuy2/AutoBuyCreator'

import GodsEyeFilterCreator from './components/screens/GodsEye/FilterCreator2/FilterCreator'
import GodsEyeFilterCreatorMasterCraft from './components/screens/GodsEye/FilterCreatorMasterCraft/FilterCreator'
import GodsEyeFilterCreatorGM from './components/screens/GodsEye/FilterCreator/FilterCreatorGM'
import GodsEyeEditFilterGM from './components/screens/GodsEye/EditFilter2/EditFilterGM'
import GodsEyeEditFilterMasterCraft from './components/screens/GodsEye/EditFilterMasterCraft/EditFilter'
import GodsEyeGenerateWallet from './components/screens/GodsEye/GenerateWallet2/GenerateWallet'
import GodsEyeGenerateCode from './components/screens/GodsEye/GenerateCode/GenerateCode'
import GodsEyeModal from './components/screens/GodsEye/Modal'
import GodsEyeModalX from './components/screens/GodsEye/ModalX'
import GodsEyeModalLayout from './components/screens/GodsEye/ModalLayout'
import GodsEyeShowBugs from './components/screens/GodsEye/ShowBugs/ShowBugs'
import GodsEyeShowFilters from './components/screens/GodsEye/ShowFilters2/ShowFilters'
import GodsEyeShowFiltersGM from './components/screens/GodsEye/ShowFilters2/ShowFiltersGM'
import GodsEyeShowFiltersMasterCraft from './components/screens/GodsEye/ShowFiltersMasterCraft/ShowFilters'
import GodsEyeLogs from './components/screens/GodsEye/Logs/Logs'
import GodsEyeTokens from './components/screens/GodsEye/Tokens/Tokens'
import GodsEyeAutoBuyCreator from './components/screens/GodsEye/AutoBuyCreator2/AutoBuyCreator'
import GodsEyeShowBags from './components/screens/GodsEye/ShowBags2/ShowBags'
import GodsEyeShowBagsReal from './components/screens/GodsEye/ShowBagsReal2/ShowBags'
import GodsEyeEditAutoBuy from './components/screens/GodsEye/EditAutoBuy2/AutoBuyCreator'

import MastercraftFilterCreator from './components/screens/Mastercraft/FilterCreator2/FilterCreator'
import MastercraftFilterCreatorMasterCraft from './components/screens/Mastercraft/FilterCreatorMasterCraft/FilterCreator'
import MastercraftFilterCreatorGM from './components/screens/Mastercraft/FilterCreator/FilterCreatorGM'
import MastercraftEditFilterGM from './components/screens/Mastercraft/EditFilter2/EditFilterGM'
import MastercraftEditFilterMasterCraft from './components/screens/Mastercraft/EditFilterMasterCraft/EditFilter'
import MastercraftGenerateWallet from './components/screens/Mastercraft/GenerateWallet2/GenerateWallet'
import MastercraftGenerateCode from './components/screens/Mastercraft/GenerateCode/GenerateCode'
import MastercraftModal from './components/screens/Mastercraft/Modal'
import MastercraftModalX from './components/screens/Mastercraft/ModalX'
import MastercraftModalLayout from './components/screens/Mastercraft/ModalLayout'
import MastercraftShowBugs from './components/screens/Mastercraft/ShowBugs/ShowBugs'
import MastercraftShowFilters from './components/screens/Mastercraft/ShowFilters2/ShowFilters'
import MastercraftShowFiltersGM from './components/screens/Mastercraft/ShowFilters2/ShowFiltersGM'
import MastercraftShowFiltersMasterCraft from './components/screens/Mastercraft/ShowFiltersMasterCraft/ShowFilters'
import MastercraftLogs from './components/screens/Mastercraft/Logs/Logs'
import MastercraftTokens from './components/screens/Mastercraft/Tokens/Tokens'
import MastercraftAutoBuyCreator from './components/screens/Mastercraft/AutoBuyCreator2/AutoBuyCreator'
import MastercraftShowBags from './components/screens/Mastercraft/ShowBags2/ShowBags'
import MastercraftShowBagsReal from './components/screens/Mastercraft/ShowBagsReal2/ShowBags'
import MastercraftEditAutoBuy from './components/screens/Mastercraft/EditAutoBuy2/AutoBuyCreator'

// 5452240340

export const router = createBrowserRouter([
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/sync',
    element: <Sync />
  },
  {
    path: '/modal',
    children: [
      {
        element: <Modal />,
        index: true
      },
      {
        element: <ModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <FilterCreator />
          },
          {
            path: 'show-filters',
            element: <ShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <GenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <GenerateCode />
          },
          {
            path: 'logs',
            element: <Logs />
          },
          {
            path: 'show-bags',
            element: <ShowBags />
          },
          {
            path: 'show-bags-real',
            element: <ShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <AutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <EditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <FilterCreatorGM />
          },

             {
            path: 'create-filter-mastercraft',
            element: <FilterCreatorMasterCraft />
          },


                       {
            path: 'edit-filter-mastercraft',
            element: <EditFilterMasterCraft />
          },




                       {
            path: 'edit-filter-gm',
            element: <EditFilterGM />
          },

                       {
            path: 'modalx',
            element: <ModalX />
          },

          {
            path: 'show-filters-mastercraft',
            element: <ShowFiltersMasterCraft />
          },




          {
            path: 'show-filters-gm',
            element: <ShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/free',
    children: [
      {
        element: <FreeModal />,
        index: true
      },
      {
        element: <FreeModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <FreeFilterCreator />
          },
          {
            path: 'show-filters',
            element: <FreeShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <FreeGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <FreeGenerateCode />
          },
          {
            path: 'logs',
            element: <FreeLogs />
          },
          {
            path: 'show-bags',
            element: <FreeShowBags />
          },
          {
            path: 'show-bags-real',
            element: <FreeShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <FreeAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <FreeEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <FreeFilterCreatorGM />
          },

             {
            path: 'create-filter-mastercraft',
            element: <FreeFilterCreatorMasterCraft />
          },


                       {
            path: 'edit-filter-mastercraft',
            element: <FreeEditFilterMasterCraft />
          },




                       {
            path: 'edit-filter-gm',
            element: <FreeEditFilterGM />
          },

                       {
            path: 'modalx',
            element: <FreeModalX />
          },

          {
            path: 'show-filters-mastercraft',
            element: <FreeShowFiltersMasterCraft />
          },




          {
            path: 'show-filters-gm',
            element: <FreeShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/basic',
    children: [
      {
        element: <BasicModal />,
        index: true
      },
      {
        element: <BasicModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <BasicFilterCreator />
          },
          {
            path: 'show-filters',
            element: <BasicShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <BasicGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <BasicGenerateCode />
          },
          {
            path: 'logs',
            element: <BasicLogs />
          },
          {
            path: 'show-bags',
            element: <BasicShowBags />
          },
          {
            path: 'show-bags-real',
            element: <BasicShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <BasicAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <BasicEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <BasicFilterCreatorGM />
          },

             {
            path: 'create-filter-mastercraft',
            element: <BasicFilterCreatorMasterCraft />
          },


                       {
            path: 'edit-filter-mastercraft',
            element: <BasicEditFilterMasterCraft />
          },




                       {
            path: 'edit-filter-gm',
            element: <BasicEditFilterGM />
          },

                       {
            path: 'modalx',
            element: <BasicModalX />
          },

          {
            path: 'show-filters-mastercraft',
            element: <BasicShowFiltersMasterCraft />
          },




          {
            path: 'show-filters-gm',
            element: <BasicShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/pro',
    children: [
      {
        element: <ProModal />,
        index: true
      },
      {
        element: <ProModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <ProFilterCreator />
          },
          {
            path: 'show-filters',
            element: <ProShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <ProGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <ProGenerateCode />
          },
          {
            path: 'logs',
            element: <ProLogs />
          },
          {
            path: 'show-bags',
            element: <ProShowBags />
          },
          {
            path: 'show-bags-real',
            element: <ProShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <ProAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <ProEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <ProFilterCreatorGM />
          },

             {
            path: 'create-filter-mastercraft',
            element: <ProFilterCreatorMasterCraft />
          },


                       {
            path: 'edit-filter-mastercraft',
            element: <ProEditFilterMasterCraft />
          },




                       {
            path: 'edit-filter-gm',
            element: <ProEditFilterGM />
          },

                       {
            path: 'modalx',
            element: <ProModalX />
          },

          {
            path: 'show-filters-mastercraft',
            element: <ProShowFiltersMasterCraft />
          },




          {
            path: 'show-filters-gm',
            element: <ProShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/godseye',
    children: [
      {
        element: <GodsEyeModal />,
        index: true
      },
      {
        element: <GodsEyeModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <GodsEyeFilterCreator />
          },
          {
            path: 'show-filters',
            element: <GodsEyeShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <GodsEyeGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <GodsEyeGenerateCode />
          },
          {
            path: 'logs',
            element: <GodsEyeLogs />
          },
          {
            path: 'show-bags',
            element: <GodsEyeShowBags />
          },
          {
            path: 'show-bags-real',
            element: <GodsEyeShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <GodsEyeAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <GodsEyeEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <GodsEyeFilterCreatorGM />
          },

             {
            path: 'create-filter-mastercraft',
            element: <GodsEyeFilterCreatorMasterCraft />
          },


                       {
            path: 'edit-filter-mastercraft',
            element: <GodsEyeEditFilterMasterCraft />
          },




                       {
            path: 'edit-filter-gm',
            element: <GodsEyeEditFilterGM />
          },

                       {
            path: 'modalx',
            element: <GodsEyeModalX />
          },

          {
            path: 'show-filters-mastercraft',
            element: <GodsEyeShowFiltersMasterCraft />
          },




          {
            path: 'show-filters-gm',
            element: <GodsEyeShowFiltersGM />
          }

        ]
      }
    ]
  },
  {
    path: '/mastercraft',
    children: [
      {
        element: <MastercraftModal />,
        index: true
      },
      {
        element: <MastercraftModalLayout />,
        children: [
          {
            path: 'create-filter',
            element: <MastercraftFilterCreator />
          },
          {
            path: 'show-filters',
            element: <MastercraftShowFilters />
          },
          {
            path: 'generate-wallet',
            element: <MastercraftGenerateWallet />
          },
          {
            path: 'generate-referral-code',
            element: <MastercraftGenerateCode />
          },
          {
            path: 'logs',
            element: <MastercraftLogs />
          },
          {
            path: 'show-bags',
            element: <MastercraftShowBags />
          },
          {
            path: 'show-bags-real',
            element: <MastercraftShowBagsReal />
          },
          {
            path: 'show-filters/:id/auto-buy',
            element: <MastercraftAutoBuyCreator />
          },          
          {
            path: 'show-filters/:id/edit-auto-buy',
            element: <MastercraftEditAutoBuy />
          },

             {
            path: 'create-filter-gm',
            element: <MastercraftFilterCreatorGM />
          },

             {
            path: 'create-filter-mastercraft',
            element: <MastercraftFilterCreatorMasterCraft />
          },


                       {
            path: 'edit-filter-mastercraft',
            element: <MastercraftEditFilterMasterCraft />
          },




                       {
            path: 'edit-filter-gm',
            element: <MastercraftEditFilterGM />
          },

                       {
            path: 'modalx',
            element: <MastercraftModalX />
          },

          {
            path: 'show-filters-mastercraft',
            element: <MastercraftShowFiltersMasterCraft />
          },




          {
            path: 'show-filters-gm',
            element: <MastercraftShowFiltersGM />
          }

        ]
      }
    ]
  }
])
