import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './FilterCreator.module.scss'
import LabelName from './LabelName/LabelName'
import Options from './OptionGM/OptionsGM'

export default function FilterCreator() {
    const nav = useNavigate()
    const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()
    const { addQuery, updateQuery } = useActions() // api
    const { savedQueries, query, user } = useSelector((state) => state.modalSlice)
    const noob = useSelector(state => state.modalSlice.noob)
    const tier = useSelector(state => state.modalSlice.tier)
    const cmb_tier = useSelector(state => state.modalSlice.cmb_tier)
    const referral_code = useSelector(state => state.modalSlice.referral_code)

    const isEdit = savedQueries.some((sq) => sq.id === query.id)
    console.log('isEdit', isEdit)


    const saveQuery = () => {

        console.log(typeof(query.mcap_min))
        console.log(isNaN(query.mcap_min))

        if (!query.selected.length) {
            // return toast.error('Nothing selected')
            return toast.error(`NO OPTIONS WERE SELECTED`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

        } else if (!query.name) {
            // return toast.error('Enter a name for this filter')
            return toast.error(`ENTER A NAME FOR THIS PROFILE`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })


        } else if (query.mcap_min_toggle && (query.mcap_min === null || query.mcap_min === "" || isNaN(query.mcap_min))) {
            // return toast.error('Enter a name for this filter')
            return toast.error(`ENTER A VALUE FOR MCAP MIN`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

      

        } else if (query.mcap_max_toggle && (query.mcap_max === null || query.mcap_max === "" || isNaN(query.mcap_max))) {
            // return toast.error('Enter a name for this filter')
            return toast.error(`ENTER A VALUE FOR MCAP MIN`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })


        }

        /*
            if (savedQueries.length > 0) {
              // return toast.error('Nothing selected')
              return toast.error('Free users are limited to one filter. Get some $CHECK for more.', {
                position: toast.POSITION.BOTTOM_RIGHT,
              })
            }
        */

        if (isEdit) {

            let data = {
                id: query.id,
                selected: query.selected,
                name: query.name,
                telegram_id: user.telegram_id,
                username: user.username,
                hash: user.hash,
                                                    sim: query.simulateBuys,
                                                    sim_sell: query.simulateSells,
                                                                        mcap_min_toggle: query.mcap_min_toggle,
                    mcap_max_toggle: query.mcap_max_toggle,
                    mcap_min: query.mcap_min,
                    mcap_max: query.mcap_max

            }

            updateQuery(data)
            // editSavedQuery(query)





            toast.success(`PROFILE '${query.name}' UPDATED. SCANNING MAINNET FOR MATCHES`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })


        } else {

            if (savedQueries.length >= 10) {




                    toast.error(`LIMIT OF 10 SIGNALS`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })





            

            } else {

                let data = {
                    id: query.id,
                    selected: query.selected,
                    includeWords: query.includeWords,
                    excludeWords: query.excludeWords,
                    name: query.name,
                    telegram_id: user.telegram_id,
                    username: user.username,
                    noob: noob,
                    hash: user.hash,
                    referral_code,
                    sim: query.simulateBuys,
                    sim_sell: query.simulateSells,
                    mcap_min_toggle: query.mcap_min_toggle,
                    mcap_max_toggle: query.mcap_max_toggle,
                    mcap_min: query.mcap_min,
                    mcap_max: query.mcap_max
                }

                console.log('data', data)


                addQuery(data)
                // addSavedQuery(query)



                toast.success(`Profile '${query.name}' CREATED, SCANNING MAINNET FOR MATCHES`, {
              icon: false,
    className: s.customToast,
          position: toast.POSITION.BOTTOM_RIGHT

        })

            }

        }
        // resetQuery()
        nav('/basic/show-filters')
    }

    const [inputValue, setInputValue] = useState('');

    return (
        <div className={s.container}>
    
   
      <Options />



    <div className={s.profileCard}>

          <div className={s.profileContainer}>
                    

   <input
      type="text"
    value={query.name}
      placeholder="PROFILE NAME"
      className={s.inputField2}
    onChange={(e) => editQuery({ ...query, name: e.target.value })}
    />

          </div>

          <div>

    <button className={s.saveButton} onClick={saveQuery}>
      {isEdit ? 'Update PROFILE' : 'CREATE PROFILE'}
    </button>
    </div>
        </div>


   




    </div>
    )
}